var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrapper"},[_c('TheSidebar'),_c('div',{staticClass:"right-content-wrapper"},[_c('v-data-table',{ref:"table",staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.perPage,"footer-props":{
        'items-per-page-options': [10, 20, 50, 100, 500],
      },"sort-by":_vm.sortBy,"sort-desc":_vm.sortDirection,"page":_vm.page,"server-items-length":_vm.total,"loading":_vm.spinnerActive},on:{"update:itemsPerPage":function($event){_vm.perPage=$event},"update:items-per-page":function($event){_vm.perPage=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDirection=$event},"update:sort-desc":function($event){_vm.sortDirection=$event},"update:page":function($event){_vm.page=$event},"update:serverItemsLength":function($event){_vm.total=$event},"update:server-items-length":function($event){_vm.total=$event},"update:options":_vm.getItems},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Tracking results")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1),_c('div',{staticStyle:{"display":"flex"}},[_c('v-text-field',{staticStyle:{"margin-right":"10px","margin-left":"10px"},attrs:{"label":"Search"},model:{value:(_vm.searchKeyword),callback:function ($$v) {_vm.searchKeyword=$$v},expression:"searchKeyword"}}),_c('v-select',{staticStyle:{"margin-right":"10px","margin-left":"10px"},attrs:{"items":_vm.websites,"item-text":"domain_data.title","item-value":"domain","label":"Website","persistent-hint":""},model:{value:(_vm.selectedWebsite),callback:function ($$v) {_vm.selectedWebsite=$$v},expression:"selectedWebsite"}}),_c('v-dialog',{ref:"dialogStartDate",attrs:{"return-value":_vm.startDate,"width":"290px"},on:{"update:returnValue":function($event){_vm.startDate=$event},"update:return-value":function($event){_vm.startDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"margin-right":"10px","margin-left":"10px"},attrs:{"label":"Start Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalStartDate),callback:function ($$v) {_vm.modalStartDate=$$v},expression:"modalStartDate"}},[_c('v-date-picker',{attrs:{"locale":"sr-Latn","scrollable":""},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.saveStartDate}},[_vm._v(" OK ")])],1)],1),_c('v-dialog',{ref:"dialogEndDate",attrs:{"return-value":_vm.endDate,"width":"290px"},on:{"update:returnValue":function($event){_vm.endDate=$event},"update:return-value":function($event){_vm.endDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"margin-right":"10px","margin-left":"10px"},attrs:{"label":"End Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalEndDate),callback:function ($$v) {_vm.modalEndDate=$$v},expression:"modalEndDate"}},[_c('v-date-picker',{attrs:{"locale":"sr-Latn","scrollable":""},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.saveEndDate}},[_vm._v(" OK ")])],1)],1)],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"}},[_vm._v("No data")])]},proxy:true},{key:"item.created_at",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format('DD MMM YYYY - hh:mm:ss'))+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }